import { Row, Col } from "react-bootstrap";

import TRoute from "../data/TRoute";
import { Link } from "react-router-dom";
import TrainRoute from "../components/TrainRoute";

import "../components/UI/styles.css";


const TrainRouteScreen = () => {
  return (
    <>
 
      <Link className="btn btn-light my-3" to="/bus">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <h1 className="text-center py-2">Karaikudi Train Route Directions</h1>
        <Row>
          {TRoute.map((route) => (
            <Col key={route.RouteNo} sm={12} md={12} lg={12} xl={12}>
              <TrainRoute route={route} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default TrainRouteScreen;
