import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import TrainTiming from "../components/TrainTiming";
import "../components/UI/styles.css";
import { useParams } from "react-router-dom";
import Train from "../data/Train";
import { FaTrain } from "react-icons/fa";


const TrainScreen = () => {
  const { RouteNo: RouteNo } = useParams();

  const filteredTrains = Train.filter((item) => item.routeNo == RouteNo);
  console.log(filteredTrains)
  return (
    <>
      <Link className="btn btn-light my-3" to="/train">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <FaTrain size={112} className="text-warning me-2 " />
        <h5 className="text-center py-2">Train Timing</h5>
        <Row>
          {filteredTrains.map((timing) => (
            <Col key={timing.Id}  md={6} lg={6} xl={6}>
              <TrainTiming timing={timing} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default TrainScreen;
