const PR =
[
    {
      "Id": 1,
      "To": "Trichy",
      "stops": "Kanadukaththan, Tirumayam, Pudukottai, Keeranur"
    },
    {
      "Id": 2,
      "To": "Pudukottai",
      "stops": "Kanadukaththan, Tirumayam, Pudukottai, Keeranur"
    },
    {
      "Id": 3,
      "To": "Tanjavure",
      "stops": "Kanadukaththan, Tirumayam, Pudukottai, Gandarvakottai"
    },
    { "Id": 4, "To": "Karur", "stops": "Tirumayam,  Pudukottai, Trichy" },
    {
      "Id": 5,
      "To": "Kumbakonam",
      "stops": "Kanadukaththan,  Pudukottai, Gandarvakottai,Tanjavure, Kumbakonam"
    },
    {
      "Id": 6,
      "To": "Chidambaram",
      "stops": "Kanadukaththan,  Pudukottai, Gandarvakottai,Tanjavure, Kumbakonam"
    },
    { "Id": 7, "To": "Thuraiyur", "stops": "Tirumayam,  Pudukottai, Trichy" },
    {
      "Id": 8,
      "To": "Rayavaram",
      "stops": "Kanadukaththan,  Kothamanagalam, Kadiyapatti"
    },
    {
      "Id": 9,
      "To": "Konapattu",
      "stops": "Kanadukaththan, Konapattu, Tirumayam, Pudukottai, Keeranur"
    },
    { "Id": 10, "To": "Salem", "stops": "Tirumayam,  Pudukottai, Trichy" },
    {
      "Id": 11,
      "To": "Tiruppur",
      "stops": "Tirumayam,  Pudukottai, Trichy, Karur"
    },
    { "Id": 12, "To": "Aranthanki", "stops": "Pallathur" },
    { "Id": 13, "To": "Erode", "stops": "Tirumayam,  Pudukottai, Trichy, Karur" },
    {
      "Id": 14,
      "To": "Thiruvannamalai",
      "stops": "Tirumayam,  Pudukottai, Trichy"
    },
    { "Id": 15, "To": "Vellur", "stops": "Tirumayam,  Pudukottai, Trichy" },
    {
      "Id": 16,
      "To": "Mettupalayam",
      "stops": "Tirumayam,  Pudukottai, Trichy, Tiruppur"
    },
    {
      "Id": 17,
      "To": "Mannarkudi",
      "stops": "Kanadukaththan, Tirumayam, Pudukottai, Gandarvakottai"
    },
    {
      "Id": 18,
      "To": "Coimbatore",
      "stops": "Tirumayam,  Pudukottai, Trichy, Karur"
    }
  ]
  

  
  export default PR 