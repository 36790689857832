import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import SandScreen from './screens/SandScreen';
import StagesScreen from './screens/StagesScreen';
import CalculationScreen from './screens/CalculationScreen';
import BusScreen from './screens/BusScreen';
import DevakottaiScreen from './screens/DevakottaiScreen';
import DevakottaiRouteScreen from './screens/DevakottaiRouteScreen';
import ThiruppathurScreen from './screens/ThiruppathurScreen';
import ThiruppathurRouteScreen from './screens/ThiruppathurRouteScreen';
import PudukottaiScreen from './screens/PudukottaiScreen';
import PudukottaiRouteScreen from './screens/PudukottaiRouteScreen';
import KallalScreen from './screens/KallalScreen';
import KallalRouteScreen from './screens/KallalRouteScreen';
import AranthankiScreen from './screens/AranthankiScreen';
import AranthankiRouteScreen from './screens/AranthankiRouteScreen';
import PrivateScreen from './screens/PrivateScreen';
import PrivateRoutecreen from './screens/PrivateRouteScreen';
import TrainScreen from './screens/TrainScreen';
import TrainRoutecreen from './screens/TrainRouteScreen';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path='/sand/' element={<SandScreen />} />
      <Route path='/stages/' element={<StagesScreen />} />
      <Route path='/calculation/' element={<CalculationScreen />} />
      <Route path='/bus/' element={<BusScreen />} />
      <Route path='/devakottai/:TownId' element={<DevakottaiScreen />} />
      <Route path='/devakottai/' element={<DevakottaiRouteScreen />} />
      <Route path='/thiruppathur/:TownId' element={<ThiruppathurScreen />} />
      <Route path='/thiruppathur/' element={<ThiruppathurRouteScreen />} />
      <Route path='/pudukottai/:TownId' element={<PudukottaiScreen />} />
      <Route path='/pudukottai/' element={<PudukottaiRouteScreen />} />
      <Route path='/kallal/:TownId' element={<KallalScreen />} />
      <Route path='/kallal/' element={<KallalRouteScreen />} />
      <Route path='/aranthanki/:TownId' element={<AranthankiScreen />} />
      <Route path='/aranthanki/' element={<AranthankiRouteScreen />} />
      <Route path='/private/:TownId' element={<PrivateScreen />} />
      <Route path='/private/' element={<PrivateRoutecreen />} />
      <Route path='/train/:RouteNo' element={<TrainScreen />} />
      <Route path='/train/' element={<TrainRoutecreen />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
