import { FaHouseUser } from "react-icons/fa";
import { Card } from "react-bootstrap";
const Product = ({ product }) => {
  return (
    <>
      <section className="register bg-white py-6 overflow-hidden">
        <div className="container position-relative">
          <div class="row hstack g-4">
            <div className="text-center mb-5">
              <FaHouseUser size={56} className="text-success me-2" />
              <h4 className="text-uppercase fw-bold text-primary">
                {product.Heading}
              </h4>
            </div>
            <div className="row">
              <h6 className="fs-6">{product.Description1}</h6>
              <hr></hr>
              <h6 className="fs-6">{product.Description2}</h6>
              <hr></hr>
              <h6 className="fs-6">{product.Description3}</h6>
              <hr></hr>
              <div className="text-center mb-5">
                <h4 className="text-uppercase fw-bold text-primary">
                  {product.Description4}
                </h4>
              </div>
              <h6 className="fs-6">{product.Description5}</h6>
              <hr></hr>
              <h6 className="fs-6">{product.Description6}</h6>
              <hr></hr>
              <h6 className="fs-6">{product.Description7}</h6>
              <hr></hr>
              <hr className="w-25 mx-auto" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
