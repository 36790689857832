import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Stages from "../components/Stages";
import "../components/UI/styles.css";

import HC02 from "../data/HC02";

const StagesScreen = () => {
  const navigate = useNavigate();
  const introductionHandler = () => {
    navigate("/");
  };

  const sandHandler = () => {
    navigate("/sand");
  };
  const calculationHandler = () => {
    navigate("/calculation");
  };

  return (
    <>
      <Row>
        <img
          src="images/stages.JPEG"
          alt=""
          className="img-fluid roundedCircle width=50% mb-5"
        />
      </Row>
      <form>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger btn mx-2 mb-2 mt-4"
            type="button"
            style={{ width: 400 }}
            onClick={() => {
              introductionHandler();
            }}
          >
            Home
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger mx-2  mb-2"
            type="button"
            style={{ width: 400 }}
            onClick={() => {
              sandHandler();
            }}
          >
            Sand
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger mx-2  mb-2"
            type="button"
            style={{ width: 400 }}
            onClick={() => {
              calculationHandler();
            }}
          >
            Estimation - மதிப்பீடு
          </Button>
        </div>
      </form>
      <section className="register text-success py-3 overflow-hidden">
        <h1 className="text-center py-2">Introduction to Different Sands</h1>
        <Row>
          {HC02.map((product) => (
            <Col key={product._id} sm={12} md={12} lg={12} xl={12}>
              <Stages product={product} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default StagesScreen;
