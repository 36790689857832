const TRoute = [
  { RouteNo: 1, Route: "Ramanathapuram", Via: "Manamadurai" },
  { RouteNo: 2, Route: "Vriddhachalam", Via: "Tiruchchirappalli" },
  { RouteNo: 3, Route: "Aruppukkottai", Via: "Manamadurai" },
  { RouteNo: 4, Route: "Thanjavur", Via: "Tiruchchirappalli" },
  { RouteNo: 5, Route: "Arantangi", Via: "Pattukottai" },
  { RouteNo: 6, Route: "KARUR", Via: "Tiruchchirappalli" },
  { RouteNo: 7, Route: "Karaikudi", Via: "Halt" },
];

export default TRoute;
