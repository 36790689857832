import { Button,Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Calculation from '../components/Calculation';
import "../components/UI/styles.css";

import HC03 from '../data/HC03';


const CalculationScreen = () => {
  const navigate = useNavigate();
  const sandHandler = () => {
    navigate("/sand");
  };

  const stagesHandler = () => {
    navigate("/stages");
  };
  const introductionHandler = () => {
    navigate("/");
  };

  // const biHandler = () => {
  //   navigate("/powerbi");
  // };

  return (
    <>
 
 
          
          <Row>
                  <img
                    src="images/calculation.JPEG"
                    alt=""
                    className="img-fluid roundedCircle width=50% mb-5"
                  />
                </Row>
                <form>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger btn mx-2 mb-2 mt-4"
            type="button"
            style={{width: 400}}
            onClick={() => {
              introductionHandler();
            }}
          >
            Home
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              stagesHandler();
            }}
          >
            Construction Stages - கட்டுமான நிலைகள்
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              sandHandler();
            }}
          >
            Sand
          </Button>
        </div>
      </form>
      <section className="register text-success py-3 overflow-hidden">
      <h1 className="text-center py-2">House Construction Cost Estimation</h1>
          <Row>
            {HC03.map((product) => (
              <Col key={product._id} sm={12} md={12} lg={12} xl={12}>
                <Calculation product={product} />
              </Col>
            ))}
          </Row>
          </section>
        </>

    
  );
};

export default CalculationScreen;
