import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import KR from "../data/KR";

import "../components/UI/styles.css";
import KallalRoute from "../components/KallalRoute";

const KallalRouteScreen = () => {
  return (
    <>
      <Link className="btn btn-light my-3" to="/bus">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <h1 className="text-center py-2">Buses Via Kallal Route</h1>
        <Row>
          {KR.map((route) => (
            <Col key={route._id} sm={12} md={12} lg={12} xl={12}>
              <KallalRoute route={route} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default KallalRouteScreen;
