const HC02 =
[
  {
    "Id": 1,
    "Heading": "Footing",
    "Description1": "Footing – Scope of work: For the isolated footing, the following works will happen.",
    "Description2": "Marking of grids, Excavation of footing as per design with JCB or Manual labour",
    "Description3": "Setting up a reference level for the building, PCC for all the footings.",
    "Description4": "Barbending for footing mat and columns, Column erection and footing concrete.",
    "Description5": "PCC for plinth beam, Barbending for plinth beam and shuttering, Concrete works for plinth beam",
    "Heading2": "காலடி",
    "Description7": "அடிவாரம் - வேலையின் நோக்கம்: தனிமைப்படுத்தப்பட்ட அடிவாரத்திற்கு, பின்வரும் பணிகள் நடக்கும்.",
    "Description8": "கட்டங்களைக் குறித்தல், ஜேசிபி அல்லது கையேடு உழைப்பைக் கொண்டு வடிவமைப்பின்படி அடிவாரத்தைத் தோண்டுதல்",
    "Description9": "கட்டிடத்திற்கான குறிப்பு அளவை அமைத்தல், அனைத்து அடிப்பகுதிகளுக்கும் பி.சி.சி.",
    "Description10": "ஃபுட்டிங் பாய் மற்றும் நெடுவரிசைகளுக்கு முட்புதுதல், நெடுவரிசை விறைப்பு மற்றும் கால் கான்கிரீட்.",
    "Description11": "பிளிந்த் பீமிற்கான பிசிசி, ப்ளிந்த் பீம் மற்றும் ஷட்டருக்கான பார்பெண்டிங், ப்ளிந்த் பீமிற்கான கான்கிரீட் வேலைகள்"
  },
  {
    "Id": 2,
    "Heading": "Basemen",
    "Description1": "Basement – Scope of works: The basement is the part of the building between ground floor level and plinth level. Basement is the base part of the building.",
    "Description2": "Basement can be either filled or usable space. If the basement level is less than 6 feet, it can be filled with filling soil or quarry dust over which the ground floor will be built.",
    "Description3": "If it is more than 6 feet, a floor space can be created for storage or car park purposes with a minimum 7.5 feet height by providing a roof slab between natural ground level and ground floor level.",
    "Description4": "For regular residential buildings, basements are usually filled with filling soil and are consolidated to provide an elevated ground floor level. It is recommended to have a basement level 2 feet above the regular natural ground level and filled with filling soil.",
    "Description5": "The important thing to keep in mind is that the higher the basement level, the bigger the ramp or staircase is required to access the top of the basement level. For 5 feet basement level, we need 10 steps, and is 9 feet long staircase required to reach the basement level. Same way at least 5 feet ramp is required for the car to climb the basement level.",
    "Description6": "Excavation for underground tanks like sump and septic tank, Construction of underground tanks, Earth filling for basement, Consolidation of filling soil and PCC for basement.",
    "Heading2": "பேஸ்மேன்",
    "Description7": "அடித்தளம் - வேலைகளின் நோக்கம்: அடித்தளம் என்பது தரைத்தளம் மற்றும் பீடம் மட்டத்திற்கு இடையில் உள்ள கட்டிடத்தின் ஒரு பகுதியாகும். அடித்தளம் கட்டிடத்தின் அடிப்படை பகுதியாகும்.",
    "Description8": "அடித்தளத்தை நிரப்பலாம் அல்லது பயன்படுத்தக்கூடிய இடமாக இருக்கலாம். அடித்தள மட்டம் 6 அடிக்கு குறைவாக இருந்தால், அதில் தரைத்தளம் கட்டப்படும் மண் அல்லது குவாரி தூசியை நிரப்பலாம்.",
    "Description9": "இது 6 அடிக்கு மேல் இருந்தால், இயற்கையான தரைமட்டத்திற்கும் தரைத்தளத்திற்கும் இடையில் கூரை ஸ்லாப்பை வழங்குவதன் மூலம் குறைந்தபட்சம் 7.5 அடி உயரத்துடன் சேமிப்பு அல்லது கார் பார்க்கிங் நோக்கங்களுக்காக ஒரு தளத்தை உருவாக்கலாம்.",
    "Description10": "வழக்கமான குடியிருப்பு கட்டிடங்களுக்கு, அடித்தளங்கள் பொதுவாக நிரப்பப்பட்ட மண்ணால் நிரப்பப்படுகின்றன மற்றும் ஒரு உயர்ந்த தரை தளத்தை வழங்க ஒருங்கிணைக்கப்படுகின்றன. வழக்கமான இயற்கையான தரை மட்டத்திலிருந்து 2 அடி உயரத்தில் ஒரு அடித்தள மட்டம் மற்றும் நிரப்பு மண்ணால் நிரப்பப்படுவது பரிந்துரைக்கப்படுகிறது.",
    "Description11": "மனதில் கொள்ள வேண்டிய முக்கியமான விஷயம் என்னவென்றால், அடித்தள மட்டம் அதிகமாக இருந்தால், அடித்தள மட்டத்தின் மேற்பகுதியை அணுக பெரிய வளைவு அல்லது படிக்கட்டு தேவை. 5 அடி அடித்தள மட்டத்திற்கு, எங்களுக்கு 10 படிகள் தேவை, மேலும் அடித்தள மட்டத்தை அடைய 9 அடி நீள படிக்கட்டுகள் தேவை. அதே வழியில் கார் அடித்தள மட்டத்தில் ஏற குறைந்தது 5 அடி சரிவு தேவை.",
    "Description12": "சம்ப் மற்றும் செப்டிக் டேங்க் போன்ற நிலத்தடி தொட்டிகளுக்கான அகழ்வாராய்ச்சி, நிலத்தடி தொட்டிகள் கட்டுதல், அடித்தளத்திற்கு பூமி நிரப்புதல், மண்ணை நிரப்புதல் மற்றும் அடித்தளத்திற்கு பி.சி.சி."
  },
  {
    "Id": 4,
    "Heading": "RCC Structure - framed structure and load bearing structure",
    "Description1": "RCC structure includes roof slab, columns, roof beams, staircase. They are one of the important part of the building construction.",
    "Description2": "There are two types of buildings, framed structure and load bearing structure.",
    "Description3": "In the load-bearing structures, roof slab load will be transferred to the brick wall.",
    "Description4": "The cost of the construction is less but we cannot change the floor plan design in the first floor. First floor plan has to be similar to the ground floor i.e, the first floor wall has to be straight above the ground floor wall.",
    "Description5": "In the framed structure buildings, First floor slab load will be transferred to the first floor roof beam, then it will be transferred to the first floor column.",
    "Description6": "The load of the first floor column will be transferred to ground floor column. Ground floor column’s load will be transferred to the footing. If the span of the two columns is more, the depth of the beam should be more. More the volume of the concrete, more steel has to be used.",
    "Heading2": "RCC கட்டமைப்பு - கட்டமைக்கப்பட்ட அமைப்பு மற்றும் சுமை தாங்கும் அமைப்பு",
    "Description7": "RCC கட்டமைப்பில் கூரை ஸ்லாப், நெடுவரிசைகள், கூரைக் கற்றைகள், படிக்கட்டு ஆகியவை அடங்கும். கட்டிட கட்டுமானத்தின் முக்கிய பாகங்களில் ஒன்றாகும்.",
    "Description8": "கட்டமைக்கப்பட்ட அமைப்பு மற்றும் சுமை தாங்கும் அமைப்பு என இரண்டு வகையான கட்டிடங்கள் உள்ளன.",
    "Description9": "சுமை தாங்கும் கட்டமைப்புகளில், கூரை ஸ்லாப் சுமை செங்கல் சுவருக்கு மாற்றப்படும்.",
    "Description10": "கட்டுமான செலவு குறைவாக உள்ளது, ஆனால் முதல் தளத்தில் உள்ள மாடித் திட்டத்தை மாற்ற முடியாது. முதல் தளத் திட்டம் தரைத் தளத்தைப் போலவே இருக்க வேண்டும், அதாவது முதல் தளச் சுவர் தரைத் தளச் சுவருக்கு நேராக இருக்க வேண்டும்.",
    "Description11": "கட்டமைக்கப்பட்ட கட்டமைப்பு கட்டிடங்களில், முதல் மாடி ஸ்லாப் சுமை முதல் மாடி கூரை கற்றைக்கு மாற்றப்படும், பின்னர் அது முதல் மாடி நெடுவரிசைக்கு மாற்றப்படும்.",
    "Description12": "முதல் தள நெடுவரிசையின் சுமை தரை தள நெடுவரிசைக்கு மாற்றப்படும். தரை தள நெடுவரிசையின் சுமை அடிவாரத்திற்கு மாற்றப்படும். இரண்டு நெடுவரிசைகளின் இடைவெளி அதிகமாக இருந்தால், பீமின் ஆழம் அதிகமாக இருக்க வேண்டும். கான்கிரீட்டின் அதிக அளவு, அதிக எஃகு பயன்படுத்தப்பட வேண்டும்."
  },
  {
    "Id": 5,
    "Heading": "The scope of RCC structure",
    "Description1": "The scope of RCC structure are:\n Bar bending and column erection upto ground floor roof level",
    "Description2": "Construction of staircase",
    "Description3": "Ground floor roof shuttering and bar bending",
    "Description4": "Electrical concealing work in ground floor roof",
    "Description5": "Concrete work in ground floor roof",
    "Description6": "Deshuttering of roof after 7 to 14 days",
    "Heading2": "RCC கட்டமைப்பின் நோக்கம்",
    "Description7": "RCC கட்டமைப்பின் நோக்கம்:\n தரைத்தள கூரை மட்டம் வரை பட்டை வளைத்தல் மற்றும் நெடுவரிசை விறைப்பு",
    "Description8": "படிக்கட்டு கட்டுதல்",
    "Description9": "தரைத்தள கூரை ஷட்டரிங் மற்றும் பார் வளைத்தல்",
    "Description10": "தரைத்தள கூரையில் மின் மறைப்பு வேலை",
    "Description11": "தரைத்தள கூரையில் கான்கிரீட் வேலை",
    "Description12": "7 முதல் 14 நாட்களுக்குப் பிறகு கூரையின் ஷட்டர்"
  },
  {
    "Id": 6,
    "Heading": "Brickwork",
    "Description1": "Brickwork is the second important part of the construction process.\n  All the internal and external wall is built using red bricks or other alternatives like aac blocks, cement blocks, fly ash bricks etc,.",
    "Description2": "Most commonly used blockwork are using chamber red bricks. Now a days, brickwork is used only as a room partition and external walls so, the load of the roof is not transferred to the walls.",
    "Description3": "The scope of brickwork stage: Marking of 1st line of brick. At this level, all the rooms will be marked along with door ways.",
    "Description4": "Brickwork up to sill level – Sill level is the bottom level of windows usually 3 feet from floor.",
    "Description5": "Brickwork up to lintel level. Lintel level is the top level of doors and windows",
    "Description6": "asting of lintel beam, sunshade and loft and Brickwork up to roof level.",
    "Heading2": "செங்கல் வேலை",
    "Description7": "செங்கல் வேலை கட்டுமான செயல்முறையின் இரண்டாவது முக்கிய பகுதியாகும்.\n  அனைத்து உள் மற்றும் வெளிப்புற சுவர்களும் சிவப்பு செங்கற்கள் அல்லது ஏசி பிளாக்ஸ், சிமென்ட் பிளாக்ஸ், ஃப்ளை ஆஷ் செங்கல் போன்ற பிற மாற்றுகளைப் பயன்படுத்தி கட்டப்பட்டுள்ளன.",
    "Description8": "மிகவும் பொதுவாக பயன்படுத்தப்படும் பிளாக்வொர்க் அறை சிவப்பு செங்கற்களைப் பயன்படுத்துகிறது. இன்று, செங்கல் வேலை ஒரு அறை பகிர்வாகவும் வெளிப்புற சுவர்களாகவும் பயன்படுத்தப்படுகிறது, எனவே கூரையின் சுமை சுவர்களுக்கு மாற்றப்படாது.",
    "Description9": "செங்கல் வேலை கட்டத்தின் நோக்கம்: செங்கலின் 1 வது வரியைக் குறிப்பது. இந்த நிலையில், அனைத்து அறைகளும் கதவு வழிகளுடன் குறிக்கப்படும்.",
    "Description10": "சன்னல் நிலை வரை செங்கல் வேலை - சன்னல் நிலை என்பது பொதுவாக தரையிலிருந்து 3 அடி உயரமுள்ள ஜன்னல்களின் கீழ் மட்டமாகும்.",
    "Description11": "லிண்டல் நிலை வரை செங்கல் வேலை. லிண்டல் நிலை என்பது கதவுகள் மற்றும் ஜன்னல்களின் மேல் நிலை",
    "Description12": "லிண்டல் பீம், சன் ஷேட் மற்றும் மாடி மற்றும் கூரை மட்டம் வரை செங்கல் வேலை."
  },
  {
    "Id": 7,
    "Heading": "Plastering",
    "Description1": "Plastering is the final process of construction for the structure. When the brickwork is over, we will plaster the uneven surface using fine mortar.",
    "Description2": "Once the brickwork is over, the uneven surface of the brickwork will be covered by a fine cement and sand mixture called mortar.",
    "Description3": "Before plastering, the electrical conduit lines will be laid in the brick wall.",
    "Description4": "All the electrical concealed boxes will be laid.",
    "Description5": "Once the concealing process is over, door frames will be fitted.",
    "Description6": "Then, interior and exterior plastering will be done. Button marks will be made to define the finished plastering level to attain the smooth and even surface.",
    "Heading2": "ப்ளாஸ்டெரிங்",
    "Description7": "ப்ளாஸ்டெரிங் என்பது கட்டமைப்பிற்கான கட்டுமானத்தின் இறுதி செயல்முறையாகும். செங்கல் வேலை முடிந்ததும், சீரற்ற மேற்பரப்பை நன்றாக மோட்டார் பயன்படுத்தி பூசுவோம்.",
    "Description8": "செங்கல் வேலை முடிந்ததும், செங்கல் வேலையின் சீரற்ற மேற்பரப்பு மோட்டார் எனப்படும் சிமெண்ட் மற்றும் மணல் கலவையால் மூடப்படும்.",
    "Description9": "ப்ளாஸ்டெரிங் செய்வதற்கு முன், செங்கல் சுவரில் மின்சார குழாய் கோடுகள் போடப்படும்.",
    "Description10": "அனைத்து மின் மறைக்கும் பெட்டிகளும் போடப்படும்.",
    "Description11": "மறைக்கும் செயல்முறை முடிந்ததும், கதவு பிரேம்கள் பொருத்தப்படும்.",
    "Description12": "பின்னர், உள் மற்றும் வெளிப்புற ப்ளாஸ்டெரிங் செய்யப்படும். மென்மையான மற்றும் சமமான மேற்பரப்பை அடைய முடிக்கப்பட்ட ப்ளாஸ்டெரிங் அளவை வரையறுக்க பட்டன் மதிப்பெண்கள் செய்யப்படும்."
  },
  {
    "Id": 8,
    "Heading": "Plastering – Scope of works",
    "Description1": "Scope of plastering works are: \n Electrical concealing work",
    "Description2": "Door frame fitting",
    "Description3": "Button mark for plastering",
    "Description4": "Interior plastering",
    "Description5": "Exterior plastering",
    "Description6": "Elevation plastering and Kitchen slab works",
    "Heading2": "ப்ளாஸ்டெரிங் - வேலைகளின் நோக்கம்",
    "Description7": "பூச்சு வேலைகளின் நோக்கம்: \n மின் மறைப்பு வேலை",
    "Description8": "கதவு சட்ட பொருத்துதல்",
    "Description9": "ப்ளாஸ்டெரிங் செய்வதற்கான பொத்தான் குறி",
    "Description10": "உள்துறை ப்ளாஸ்டெரிங்",
    "Description11": "வெளிப்புற ப்ளாஸ்டெரிங்",
    "Description12": "எலிவேஷன் ப்ளாஸ்டெரிங் மற்றும் கிச்சன் ஸ்லாப் வேலைகள்"
  },
  {
    "Id": 9,
    "Heading": "Finishing stage",
    "Description1": "The scope of finishing stage works are as follows: Flooring, Joineries – Windows and Door Shutters",
    "Description2": "Plumbing concealing works in bathrooms and Plumbing delivery lines and fittings",
    "Description3": "Electrical wiring and fittings",
    "Description4": "Handrails and Wall claddings",
    "Description5": "Elevation works and Interior Decoration",
    "Description6": "Painting and Cleaning",
    "Heading2": "இறுதி நிலை",
    "Description7": "நிலைப் பணிகளை முடிப்பதற்கான நோக்கம் பின்வருமாறு: தரையமைப்பு, இணைப்புகள் - ஜன்னல்கள் மற்றும் கதவு ஷட்டர்கள்",
    "Description8": "குளியலறைகள் மற்றும் பிளம்பிங் டெலிவரி லைன்கள் மற்றும் ஃபிட்டிங்குகளில் பிளம்பிங் மறைத்தல் வேலை செய்கிறது",
    "Description9": "மின் வயரிங் மற்றும் பொருத்துதல்கள்",
    "Description10": "கைப்பிடிகள் மற்றும் சுவர் உறைப்பூச்சுகள்",
    "Description11": "உயர வேலைகள் மற்றும் உள்துறை அலங்காரம்",
    "Description12": "ஓவியம் மற்றும் சுத்தம்"
  }
]


  export default HC02  