import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const PrivateRoute = ({ route }) => {
  return (
    <>
    console.log(route)
    <Card className="my-3 p-3 rounded">
      <Card.Body>
        <Link to={`/private/${route.Id}`}>
          <Card.Title as="div" className="product-title">
            <strong>{route.To}</strong>
          </Card.Title>
        </Link>
      </Card.Body>
    </Card>
    </>
  );
};

export default PrivateRoute;
