const AR =
[
    {
      "Id": 1,
      "To": "Aranthanki",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Avanam"
    },
    {
      "Id": 2,
      "To": "Pattukottai",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Avanam"
    },
    {
      "Id": 3,
      "To": "Avanam",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Avanam"
    },
    {
      "Id": 4,
      "To": "Avudaiyar Koil",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Embal"
    },
    { "Id": 5, "To": "Embal", "stops": "Kottaiyur, Kandanur, Puduvayal, Embal" },
    {
      "Id": 6,
      "To": "Pudupattinam",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki"
    },
    {
      "Id": 7,
      "To": "Perumaruth",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki"
    },
    {
      "Id": 8,
      "To": "Thiruvarur",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Avanam, Pattukottai"
    },
    {
      "Id": 9,
      "To": "Nagoor",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Avanam, Pattukottai"
    },
    {
      "Id": 10,
      "To": "Trichy",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki"
    },
    {
      "Id": 11,
      "To": "NAGAI",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Avanam, Pattukottai"
    },
    {
      "Id": 12,
      "To": "Peravoorani",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki"
    },
    {
      "Id": 13,
      "To": "K Pattinam",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki"
    },
    {
      "Id": 14,
      "To": "Vedharnayam",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Avanam, Pattukottai"
    },
    {
      "Id": 15,
      "To": "Kattumavadi",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki, Kattumavadi"
    },
    {
      "Id": 16,
      "To": "Meemisal",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki"
    },
    {
      "Id": 17,
      "To": "Subrmaniyapuram",
      "stops": "Kottaiyur, Kandanur, Puduvayal, Kallur, Puthupatti, Aranthanki"
    }
  ]
  


  
  export default AR 