import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Timing = ({ timing }) => {
  return (
    <>
    
    <h6>{timing.Time}</h6>
    </>
  );
};

export default Timing;
