const HC01 = 
[
  {
    "Id": 1,
    "Heading": "Introduction",
    "Description1": "The materials used to build a house include cement, bricks, steel bars, sand, gravel, wood, roofing materials like shingles or tiles, insulation, drywall, plumbing and electrical fixtures, windows, and doors.",
    "Description2": "These materials are essential for constructing the foundation, structure, walls, roof, and interior finishes of a building.",
    "Description3": "The two basically found types of sand are: M Sand (Manufactured Sand) and P Sand (Plastering Sand). Both are selected according to the construction project requirement and the quality of sand used for their final build.",
    "Description4": "அறிமுகம்",
    "Description5": "ஒரு வீட்டைக் கட்டுவதற்குப் பயன்படுத்தப்படும் பொருட்களில் சிமென்ட், செங்கற்கள், இரும்புக் கம்பிகள், மணல், சரளை, மரம், சிங்கிள்ஸ் அல்லது டைல்ஸ் போன்ற கூரை பொருட்கள், காப்பு, உலர்வால், பிளம்பிங் மற்றும் மின் சாதனங்கள், ஜன்னல்கள் மற்றும் கதவுகள் ஆகியவை அடங்கும்.",
    "Description6": "ஒரு கட்டிடத்தின் அடித்தளம், கட்டமைப்பு, சுவர்கள், கூரை மற்றும் உட்புற பூச்சுகளை உருவாக்குவதற்கு இந்த பொருட்கள் அவசியம்.",
    "Description7": "அடிப்படையில் காணப்படும் இரண்டு மணல் வகைகள்: எம் சாண்ட் (உற்பத்தி செய்யப்பட்ட மணல்) மற்றும் பி சாண்ட் (பிளாஸ்டெரிங் மணல்). கட்டுமானத் திட்டத் தேவை மற்றும் அவற்றின் இறுதிக் கட்டத்திற்குப் பயன்படுத்தப்படும் மணலின் தரம் ஆகியவற்றின் அடிப்படையில் இரண்டும் தேர்ந்தெடுக்கப்படுகின்றன."
  },
  {
    "Id": 2,
    "Heading": "M Sand",
    "Description1": "M Sand, otherwise known as Manufactured Sand, is made out of hard granite stone crushed into cubical particles of small sizes.",
    "Description2": "It is often used as a substitute for river sand in construction due to its quality and availability.",
    "Description3": "Three types of M Sand Based on usage, it is categorized into three main types: Concreting, Plastering, and Brick or Blockwork.",
    "Description4": "எம் மணல்",
    "Description5": "M Sand, இல்லையெனில் தயாரிக்கப்பட்ட மணல் என்று அழைக்கப்படுகிறது, சிறிய அளவிலான கனசதுர துகள்களாக நொறுக்கப்பட்ட கடினமான கிரானைட் கல்லால் ஆனது.",
    "Description6": "அதன் தரம் மற்றும் கிடைக்கும் தன்மை காரணமாக கட்டுமானத்தில் ஆற்று மணலுக்கு மாற்றாக இது பெரும்பாலும் பயன்படுத்தப்படுகிறது.",
    "Description7": "மூன்று வகையான எம் சாண்ட் பயன்பாட்டின் அடிப்படையில், இது மூன்று முக்கிய வகைகளாக வகைப்படுத்தப்படுகிறது: கான்கிரீட், ப்ளாஸ்டெரிங் மற்றும் செங்கல் அல்லது பிளாக்வொர்க்."
  },
  {
    "Id": 3,
    "Heading": "M Sand for Concreting",
    "Description1": "The particle size of M sand for concreting shall be 4.75 mm to 3.5 mm.",
    "Description2": "In general, for building’s foundations, slabs, beams and columns are built with this specifications.",
    "Description3": "The typical ratio for concreting is attained by mixing 1:2:4 that covers a initial setting time of 30 minutes and final setting time of around 10 hours. It complies with IS Code 383:1970.",
    "Description4": "காங்கிரீடிங்கிற்கு எம் சாண்ட்",
    "Description5": "கான்க்ரீட் செய்வதற்கான எம் சாண்டின் துகள் அளவு 4.75 மிமீ முதல் 3.5 மிமீ வரை இருக்க வேண்டும்.",
    "Description6": "பொதுவாக, கட்டிடத்தின் அடித்தளங்களுக்கு, அடுக்குகள், விட்டங்கள் மற்றும் நெடுவரிசைகள் இந்த விவரக்குறிப்புகளுடன் கட்டப்பட்டுள்ளன.",
    "Description7": "கான்க்ரீடிங்கிற்கான வழக்கமான விகிதமானது 1:2:4 என்ற கலவையின் மூலம் அடையப்படுகிறது, இது ஆரம்ப அமைவு நேரத்தை 30 நிமிடங்கள் மற்றும் இறுதி அமைவு நேரம் சுமார் 10 மணிநேரம் ஆகும். இது IS குறியீடு 383:1970 உடன் இணங்குகிறது."
  },
  {
    "Id": 4,
    "Heading": "M Sand for Plastering",
    "Description1": "Plastering is important while finishing the interior and exterior structures like wall, ceiling, or any other surface. A fine mixture can provide a smooth finish. For this, the particle size of sand should be taken 2.36 mm to 1.2 mm mixed with the cement in a ratio of 1:4. Also, these requirements meets the IS Code 1542: 1992.",
    "Description4": "ப்ளாஸ்டெரிங் செய்வதற்கு எம் சாண்ட்",
    "Description5": "சுவர், கூரை அல்லது வேறு எந்த மேற்பரப்பிலும் உட்புற மற்றும் வெளிப்புற கட்டமைப்புகளை முடிக்கும்போது ப்ளாஸ்டெரிங் முக்கியமானது. ஒரு சிறந்த கலவை ஒரு மென்மையான பூச்சு வழங்க முடியும். இதற்கு மணலின் துகள் அளவு 2.36 மி.மீ முதல் 1.2 மி.மீ வரை சிமெண்டுடன் 1:4 என்ற விகிதத்தில் கலந்து எடுக்க வேண்டும். மேலும், இந்தத் தேவைகள் IS குறியீடு 1542: 1992 ஐப் பூர்த்தி செய்கின்றன."
  },
  {
    "Id": 5,
    "Heading": "M Sand for Brick or Blockwork",
    "Description1": "M Sand is specifically designed to provide the necessary bonding and strength required for masonry applications.",
    "Description2": "Structures like building walls, partitions, and other masonry works.",
    "Description3": "Ideal particle size for Blockwork is 3.5 mm to 2.36 mm, typically mixed with cement in a ratio of 1:5 (cement: sand).",
    "Description4": "செங்கல் அல்லது பிளாக்வொர்க்கிற்கான எம் மணல்",
    "Description5": "M Sand குறிப்பாக கொத்து பயன்பாடுகளுக்கு தேவையான பிணைப்பு மற்றும் வலிமையை வழங்க வடிவமைக்கப்பட்டுள்ளது.",
    "Description6": "கட்டிட சுவர்கள், பகிர்வுகள் மற்றும் பிற கொத்து வேலைகள் போன்ற கட்டமைப்புகள்.",
    "Description7": "பிளாக்வொர்க்கிற்கான சிறந்த துகள் அளவு 3.5 மிமீ முதல் 2.36 மிமீ வரை, பொதுவாக சிமெண்டுடன் 1:5 என்ற விகிதத்தில் கலக்கப்படுகிறது (சிமெண்ட்: மணல்)."
  },
  {
    "Id": 6,
    "Heading": "P Sand",
    "Description1": "P Sand is a type of manufactured sand used for plastering purposes.",
    "Description2": "It has finer features compared to M Sand, giving smooth and even results on the walls and the ceiling.",
    "Description3": "It is produced by crushing granite rocks and is processed to achieve a finer state suitable for plastering applications.",
    "Description4": "பி மணல்",
    "Description5": "P Sand என்பது ப்ளாஸ்டெரிங் நோக்கங்களுக்காகப் பயன்படுத்தப்படும் ஒரு வகை தயாரிக்கப்பட்ட மணல் ஆகும்.",
    "Description6": "M Sand உடன் ஒப்பிடும்போது இது சிறந்த அம்சங்களைக் கொண்டுள்ளது, சுவர்கள் மற்றும் கூரையில் மென்மையான மற்றும் சமமான முடிவுகளை அளிக்கிறது.",
    "Description7": "இது கிரானைட் பாறைகளை நசுக்குவதன் மூலம் உற்பத்தி செய்யப்படுகிறது மற்றும் ப்ளாஸ்டெரிங் பயன்பாடுகளுக்கு ஏற்ற சிறந்த நிலையை அடைய செயலாக்கப்படுகிறது."
  },
  {
    "Id": 7,
    "Heading": "M Sand and P Sand",
    "Description1": "For Concreting, M Sand is first to-go for to its strength and durability.",
    "Description2": "For Plastering, P Sand is preferred for its fine grip and smooth finish.",
    "Description3": "And for Brick or Blockwork, M Sand provides strong bonding properties.",
    "Description4": "எம் சாண்ட் மற்றும் பி சாண்ட்",
    "Description5": "கான்க்ரீட் செய்வதற்கு, எம் சாண்ட் அதன் வலிமை மற்றும் நீடித்த தன்மைக்கு முதலில் செல்ல வேண்டும்.",
    "Description6": "ப்ளாஸ்டெரிங் செய்வதற்கு, பி சாண்ட் அதன் சிறந்த பிடிப்பு மற்றும் மென்மையான பூச்சுக்கு விரும்பப்படுகிறது.",
    "Description7": "செங்கல் அல்லது பிளாக்வொர்க்கிற்கு, எம் சாண்ட் வலுவான பிணைப்பு பண்புகளை வழங்குகிறது."
  },
  {
    "Id": 8,
    "Heading": "M Sand and River Sand",
    "Description1": "M Sand has numerous advantages over natural river sand, including better consistency, higher quality, and enhanced durability for construction projects.\n Compared to river sand, M Sand is better in uniform quality and consistent in particle size with high-value strength.",
    "Description2": "River sand contains more impurities, such as clay, silt, and organic matter. It is not much preferred because excessive extraction can lead to the depletion of natural resources.",
    "Description3": "However, River sand is more expensive due to the costs associated with mining, transporting, and legal restrictions. Hence, M Sand can be cheaper as it is locally produced and doesn’t incur high transportation costs.",
    "Description4": "எம் மணல் மற்றும் ஆற்று மணல்",
    "Description5": "இயற்கையான ஆற்று மணலை விட எம் சாண்ட் பல நன்மைகளைக் கொண்டுள்ளது, இதில் சிறந்த நிலைத்தன்மை, உயர் தரம் மற்றும் கட்டுமானத் திட்டங்களுக்கான மேம்பட்ட ஆயுள் ஆகியவை அடங்கும்.\n ஆற்று மணலுடன் ஒப்பிடும்போது, ​​எம் சாண்ட் சீரான தரத்தில் சிறந்தது மற்றும் அதிக மதிப்பு வலிமையுடன் துகள் அளவில் சீரானது.",
    "Description6": "ஆற்று மணலில் களிமண், வண்டல் மற்றும் கரிமப் பொருட்கள் போன்ற அதிக அசுத்தங்கள் உள்ளன. அதிகப்படியான பிரித்தெடுத்தல் இயற்கை வளங்களின் குறைவுக்கு வழிவகுக்கும் என்பதால் இது மிகவும் விரும்பப்படுவதில்லை.",
    "Description7": "இருப்பினும், சுரங்கம், போக்குவரத்து மற்றும் சட்டரீதியான கட்டுப்பாடுகள் ஆகியவற்றுடன் தொடர்புடைய செலவுகள் காரணமாக ஆற்று மணல் மிகவும் விலை உயர்ந்தது. எனவே, எம் சாண்ட் உள்நாட்டில் உற்பத்தி செய்யப்படுவதால், அதிக போக்குவரத்துச் செலவுகள் ஏற்படாததால், மலிவாக இருக்கும்."
  },
  {
    "Id": 9,
    "Heading": "P Sand and River Sand",
    "Description1": "P Sand is better than River Sand. P Sand is produced in a controlled environment where it does not disturb natural river ecosystems.",
    "Description2": "It is made by crushing rocks in a sustainable way. As excessive mining of river sand can harm riverbeds and aquatic life, people are concerned about extraction and over-mining.",
    "Description3": "Overall, P Sand is affordable and known for its quality and smoother finish for plastering.",
    "Description4": "பி மணல் மற்றும் ஆற்று மணல்",
    "Description5": "ஆற்று மணலை விட பி சாண்ட் சிறந்தது. P மணல் இயற்கையான நதி சுற்றுச்சூழல் அமைப்புகளுக்கு இடையூறு ஏற்படாத ஒரு கட்டுப்படுத்தப்பட்ட சூழலில் உற்பத்தி செய்யப்படுகிறது.",
    "Description6": "இது நிலையான வழியில் பாறைகளை நசுக்குவதன் மூலம் செய்யப்படுகிறது. ஆற்று மணல் அள்ளுவது ஆற்றுப்படுகைகள் மற்றும் நீர்வாழ் உயிரினங்களுக்கு தீங்கு விளைவிக்கும் என்பதால், மக்கள் பிரித்தெடுத்தல் மற்றும் அதிகப்படியான சுரங்கம் பற்றி கவலைப்படுகிறார்கள்.",
    "Description7": "ஒட்டுமொத்தமாக, P Sand மலிவானது மற்றும் அதன் தரம் மற்றும் ப்ளாஸ்டெரிங்கிற்கான மென்மையான பூச்சுக்காக அறியப்படுகிறது."
  }
]

export default HC01