import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const PudukottaiRoute = ({ route }) => {
  return (
    <Card className='my-3 p-3 rounded'>
 

      <Card.Body>
      <Link to={`/devakottai/${route.Id}`}>
          <Card.Title as='div' className='product-title'>
            <strong>{route.To}</strong>
          </Card.Title>
        </Link>

        <Card.Text as='h5'>Stops</Card.Text>
        <Card.Text as='h6'>{route.stops}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PudukottaiRoute;
