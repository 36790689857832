
import { Table, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
// import Timing from "../components/Timing";
import "../components/UI/styles.css";
import { useParams } from "react-router-dom";
import Private from "../data/Private";
import { FaBus } from "react-icons/fa";

const PrivateScreen = () => {
  const { TownId: TownId } = useParams();

  const filteredCity = Private.filter((item) => item.TownId == TownId);

  return (
    <>
      <Link className="btn btn-light my-3" to="/private">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <FaBus size={112} className="text-danger me-2 " />
        <h5 className="text-center py-2">Bus Timing</h5>
        <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>Time</th>
                <th>Service</th>
                
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredCity.map((product) => (
                <tr key={product._id}>
                  {/* <td>{product._id}</td> */}
                  <td>{product.Time}</td>
                  <td>{product.Service}</td>
                  
 
                </tr>
              ))}
            </tbody>
          </Table>
      </section>
    </>
  );
};

export default PrivateScreen;
