import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const TrainRoute = ({ route }) => {
  return (
    <Card className="my-3 p-3 rounded">
      <Card.Body>
        <Link to={`/train/${route.RouteNo}`}>
          <Card.Title as="div" className="product-title">
            <strong>{route.Route}</strong>
          </Card.Title>
        </Link>
        <Row>
          <Col xs={6} sm={6} md={3} lg={3} xl={3}>
            <Card.Text as="h5">Via</Card.Text>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} xl={3}>
            <Card.Text as="h6">{route.Via}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TrainRoute;
