const Route =
[
  { "Id": 1, "To": "Devakottai", "stops": "Amaravathi Pudur, Ram Nagar" },
  {
    "Id": 2,
    "To": "Thiruvadanai",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai"
  },
  {
    "Id": 3,
    "To": "Thiruvetriyur",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai"
  },
  {
    "Id": 4,
    "To": "RS Mangalam",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, RS Mangalam, Ramnad"
  },
  {
    "Id": 5,
    "To": "Rameshwaram",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, RS Mangalam, Ramnad"
  },
  {
    "Id": 6,
    "To": "Ramnad",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, RS Mangalam, Ramnad"
  },
  {
    "Id": 7,
    "To": "Elayankudi",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, Kalaiyar Kovil,Elayankudi, Paramakudi"
  },
  {
    "Id": 8,
    "To": "Kalaiyar Kovil",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, Kalaiyar Kovil,Elayankudi, Paramakudi"
  },
  {
    "Id": 9,
    "To": "Mudhukulathur",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, Kalaiyar Kovil,Elayankudi, Paramakudi"
  },
  {
    "Id": 10,
    "To": "Paramakudi",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, Kalaiyar Kovil,Elayankudi, Paramakudi"
  },
  {
    "Id": 11,
    "To": "Thondi",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai"
  },
  {
    "Id": 12,
    "To": "Oriyur",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai"
  },
  { "Id": 13, "To": "SR Pattanam", "stops": "Amaravathi Pudur, Kallupatti" },
  {
    "Id": 14,
    "To": "Erwadi",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, RS Mangalam"
  },
  {
    "Id": 15,
    "To": "Karankadu",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, RS Mangalam, Ramnad"
  },
  {
    "Id": 16,
    "To": "Thiruppuna Vasal",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai"
  },
  {
    "Id": 17,
    "To": "Valinokkam",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, RS Mangalam, Ramnad"
  },
  {
    "Id": 18,
    "To": "Keelakarai",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, RS Mangalam, Ramnad"
  },
  {
    "Id": 19,
    "To": "SP Pattanam",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai, Thondi"
  },
  {
    "Id": 20,
    "To": "VASA",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai,Thiruvadanai"
  },
  {
    "Id": 21,
    "To": "Sivaganga",
    "stops": "Amaravathi Pudur, Ram Nagar, Devakottai, Kalaiyar Kovil"
  }
]


  
  export default Route 