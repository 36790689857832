import { Row, Col } from "react-bootstrap";

import AR from "../data/AR";
import { Link } from "react-router-dom";

import "../components/UI/styles.css";
import AranthankiRoute from "../components/AranthankiRoute";

const AranthankiRouteScreen = () => {
  return (
    <>
 
      <Link className="btn btn-light my-3" to="/bus">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <h1 className="text-center py-2">Buses Via Aranthangi Rout</h1>
        <Row>
          {AR.map((route) => (
            <Col key={route._id} sm={12} md={12} lg={12} xl={12}>
              <AranthankiRoute route={route} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default AranthankiRouteScreen;
