import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Product from "../components/Product";
import "../components/UI/styles.css";

import HC01 from "../data/HC01";

const HomeScreen = () => {
  const navigate = useNavigate();
  const homeHandler = () => {
    navigate("/");
  };

  const houseHandler = () => {
    navigate("/sand");
  };
  const busHandler = () => {
    navigate("/bus");
  };
  const trainHandler = () => {
    navigate("/train");
  };

  return (
    <>
      <header className="header">
        <div className="hero text-white pt-7">
          <div className="container-xxl margin-bottom: -500px">
            <div className="row">
              <div className="col-md-6">
                <div className="image-container mb-5 px-4">
                  <img
                    src="images/header-ebook.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-container p-4 d-flex flex-column justify-content-center h-100 mb-5">
                  <h6 className="display-6 fw-bold">Welcome to Infowisdom</h6>

                  <div className="form-container text-center">
                    <form>
                    <div className="d-grid">
                        <Button
                          className="btn-danger mx-2 mb-2"
                          type="button"
                          onClick={() => {
                            busHandler();
                          }}
                        >
                          Karaikudi Bus Timing
                        </Button>
                      </div>
                      <div className="d-grid">
                        <Button
                          className="btn-danger mx-2 mb-2"
                          type="button"
                          onClick={() => {
                            trainHandler();
                          }}
                        >
                          Karaikudi Train Timing
                        </Button>
                      </div>
                      <div className="d-grid">
                        <Button
                          className="btn-danger mx-2 mb-2"
                          type="button"
                          onClick={() => {
                            houseHandler();
                          }}
                        >
                          House Construction - வீடு கட்டுமானம்
                        </Button>
                      </div>
 
                    </form>
                    <p className="lead">
                      An application is developed with questions and answers
                      from various topics such as Ages, Averages, Trains etc..
                      of Quantitative Aptitude.
                    </p>
                    <a
                      href="https://infowisdomqa.in"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "28px",
                        fontWeight: "bold",
                      }}
                    >
                      MeenaChidam Ecommerce
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg
          className="frame-decoration"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox="0 0 1500 152.275"
          fill="#1877F2"
        >
          <title>frame-decoration</title>
          <path
            className="cls-1"
            d="M0,158.755s63.9,52.163,179.472,50.736c121.494-1.5,185.839-49.738,305.984-49.733,109.21,0,181.491,51.733,300.537,50.233,123.941-1.562,225.214-50.126,390.43-50.374,123.821-.185,353.982,58.374,458.976,56.373,217.907-4.153,284.6-57.236,284.6-57.236V351.03H0V158.755Z"
            transform="translate(0 -158.755)"
          />
        </svg>
      </header>

      <Row>
        <p className="lead">
          The information related to various topics that would be reference for
          the people has been collected from various websites and organized
          here.
        </p>
        <hr></hr>
        <p className="lead">
          மக்களுக்குப் பயன்படும் பல்வேறு தலைப்புகள் தொடர்பான தகவல்கள் பல்வேறு
          இணையதளங்களில் இருந்து சேகரிக்கப்பட்டு இங்கு வழங்கப்பட்டுள்ளன..
        </p>
      </Row>
 
    </>
  );
};

export default HomeScreen;
