import { Card, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';


const TrainTiming = ({ timing }) => {
  return (
    <>
       <Card className="my-3 p-3 rounded">
      <Card.Body>
        
          <Card.Title as="div" className="product-title">
            <strong>{timing.Train}</strong>
          </Card.Title>
          <Row>
          <Col className= "text-primary"xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h8">Destination</Card.Text>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h7">{timing.Destination}</Card.Text>
          </Col>
          </Row>
          <Row>
          <Col className= "text-primary"xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h8">Arrival</Card.Text>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h6">{timing.Arrival}</Card.Text>
          </Col>
          </Row>
          <Row>
          <Col className= "text-primary"xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h8">Departure</Card.Text>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h6">{timing.Departure}</Card.Text>
          </Col>
          </Row>
          <Row>
          <Col className= "text-primary"xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h8">Service</Card.Text>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h6">{timing.Service}</Card.Text>
          </Col>
          </Row>
          <Row>
          <Col className= "text-primary"xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="h8">Stops</Card.Text>
          </Col>
          <Col className= "text-danger" xs={6} sm={6} md={3} lg={3} xl={3}>
          <Card.Text as="p">{timing.Stops}</Card.Text>
          </Col>
          </Row>

        
      </Card.Body>
    </Card>
    
    </>
  );
};

export default TrainTiming;
