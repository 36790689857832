const PriRoute =
[
    { "Id": 1, "To": "Madurai" },
    { "Id": 2, "To": "Trichy" },
    { "Id": 3, "To": "Devakottai" },
    { "Id": 4, "To": "Dindigul" },
    { "Id": 4, "To": "Pattukottai" },
    { "Id": 5, "To": "Paramakudi" },
    { "Id": 6, "To": "Aranthanki" },
    { "Id": 7, "To": "Sivaganga" },
    { "Id": 8, "To": "Ramnad" },
    { "Id": 9, "To": "Pudukottai" },
    { "Id": 10, "To": "Thiruppathur" },
    { "Id": 11, "To": "Thiruvetriyur" },
    { "Id": 12, "To": "Peravoorani" },
    { "Id": 13, "To": "Ponnamaravathy" },
    { "Id": 14, "To": "Tiruppuvanam" },
    { "Id": 15, "To": "Avudaiyar Koil" },
    { "Id": 16, "To": "Mudhukulathur" },
    { "Id": 17, "To": "Oriyur" },
    { "Id": 18, "To": "SR Pattanam" },
    { "Id": 19, "To": "Pudupattinam" },
    { "Id": 20, "To": "Vempathur" }
  ]
  


  
  export default PriRoute 