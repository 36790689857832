import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PriRoute from "../data/PriRoute";

import "../components/UI/styles.css";
import PrivateRoute from "../components/PrivateRoute";

const PrivateRouteScreen = () => {
  return (
    <>
      <Link className="btn btn-light my-3" to="/bus">
        Go Back
      </Link>
      
      <section className="register text-success py-3 overflow-hidden">
        <h1 className="text-center py-2">Private Buses</h1>
        <Row>
          {PriRoute.map((route) => (
            <Col key={route._id} sm={12} md={12} lg={12} xl={12}>
              <PrivateRoute route={route} />
            </Col>
          ))}
          
        </Row>
      </section>
    </>
  );
};

export default PrivateRouteScreen;
