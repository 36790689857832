import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Timing from "../components/Timing";
import "../components/UI/styles.css";
import { useParams } from "react-router-dom";
import Thiruppathur from "../data/Thiruppathur";
import { FaBus } from "react-icons/fa";

const ThiruppathurScreen = () => {
  const { TownId: TownId } = useParams();

  const filteredCity = Thiruppathur.filter((item) => item.TownId == TownId);

  return (
    <>
      <Link className="btn btn-light my-3" to="/thiruppathur">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <FaBus size={112} className="text-danger me-2 " />
        <h5 className="text-center py-2">Bus Timing</h5>
        <Row>
          {filteredCity.map((timing) => (
            <Col key={timing.Id} xs={4} sm={4} md={2} lg={1} xl={1}>
              <Timing timing={timing} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default ThiruppathurScreen;
