import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PR from "../data/PR";

import "../components/UI/styles.css";
import PudukottaiRoute from "../components/PudukottaiRoute";

const PudukottaiRouteScreen = () => {
  return (
    <>
      <Link className="btn btn-light my-3" to="/bus">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <h1 className="text-center py-2">Buses Via Pudukottai Route</h1>
        <Row>
          {PR.map((route) => (
            <Col key={route._id} sm={12} md={12} lg={12} xl={12}>
              <PudukottaiRoute route={route} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default PudukottaiRouteScreen;
