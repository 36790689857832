import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Product from "../components/Product";
import "../components/UI/styles.css";

import HC01 from "../data/HC01";

const SandScreen = () => {
  const navigate = useNavigate();
  const introductionHandler = () => {
    navigate("/");
  };

  const stagesHandler = () => {
    navigate("/stages");
  };
  const calculationHandler = () => {
    navigate("/calculation");
  };

  // const biHandler = () => {
  //   navigate("/powerbi");
  // };

  return (
    <>
      <Row>
        <img
          src="images/sand.JPEG"
          alt=""
          className="img-fluid roundedCircle width=50%"
        />
      </Row>
      <form>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger btn mx-2 mb-2 mt-4"
            type="button"
            style={{width: 400}}
            onClick={() => {
              introductionHandler();
            }}
          >
            Home
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              stagesHandler();
            }}
          >
            Construction Stages - கட்டுமான நிலைகள்
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-danger mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              calculationHandler();
            }}
          >
            Estimation - மதிப்பீடு
          </Button>
        </div>
      </form>
      <section className="register text-success py-3 overflow-hidden">
      <h1 className="text-center py-2">Introduction to Different Sands</h1>
      <Row>
        {HC01.map((product) => (
          <Col key={product._id} sm={12} md={12} lg={12} xl={12}>
            <Product product={product} />
          </Col>
        ))}
      </Row>
      </section>
    </>
  );
};

export default SandScreen;
