const Train =
[
    {
      "Id": 1,
      "Train": "16849 TPJ RMM EXPRESS ",
      "Destination": "Rameswaram",
      "Arrival": "08:33",
      "Departure": "08:35",
      "Service": "Daily",
      "Stops": "Devakottai Road, Kallal, Sivaganga, Manamadurai Jn, Paramakkudi, Ramanathapuram, Uchippuli, Mandapam",
      "routeNo": 1
    },
    {
      "Id": 2,
      "Train": "22536 BSBS RMM EXP ",
      "Destination": "Mandapam",
      "Arrival": "18:25",
      "Departure": "18:30",
      "Service": "Sunday",
      "Stops": "Devakottai Road, Sivaganga, Manamadurai Jn, Paramakkudi, Ramanathapuram, Mandapam",
      "routeNo": 1
    },
    {
      "Id": 3,
      "Train": "16618 CBE RMM EXPRESS ",
      "Destination": "Ramanathapuram",
      "Arrival": "01:58",
      "Departure": "02:00",
      "Service": "Tuesday",
      "Stops": "Devakottai Road, Sivaganga, Manamadurai Jn, Paramakkudi, Ramanathapuram, Mandapam",
      "routeNo": 1
    },
    {
      "Id": 4,
      "Train": "16851 - MS RMM EXPRESS",
      "Destination": "Mandapam",
      "Arrival": "04:10",
      "Departure": "04:12",
      "Service": "Daily",
      "Stops": "Devakottai Road, Kallal, Sivaganga, Manamadurai Jn, Paramakkudi, Ramanathapuram, Mandapam, Pamban Jn",
      "routeNo": 1
    },
    {
      "Id": 5,
      "Train": "22661 - RAMESWARAM EXP",
      "Destination": "Mandapam",
      "Arrival": "00:40",
      "Departure": "00:45",
      "Service": "Daily",
      "Stops": "Sivaganga - SVGA, Manamadurai Jn - MNM, Paramakkudi - PMK, Ramanathapuram - RMD, Mandapam - MMM",
      "routeNo": 1
    },
    {
      "Id": 6,
      "Train": "07685 - SC RMM SPL",
      "Destination": "Rameswaram",
      "Arrival": "23:15",
      "Departure": "23:20",
      "Service": "Tuesday",
      "Stops": "Sivaganga (SVGA), Manamadurai Junction (MNM),Ramanathapuram",
      "routeNo": 1
    },
    {
      "Id": 7,
      "Train": "18496 - BBS RMM EXPRESS",
      "Destination": "Rameswaram",
      "Arrival": "17:50",
      "Departure": "17:55",
      "Service": "Friday",
      "Stops": "Devakottai Road (DKO), Sivaganga (SVGA), Manamadurai Junction (MNM), Paramakkudi (PMK), Ramanathapuram (RMD), Rameswaram (RMM)",
      "routeNo": 1
    },
    {
      "Id": 8,
      "Train": "07695 - SC RMD SPL",
      "Destination": "Ramanathapuram",
      "Arrival": "20:25",
      "Departure": "20:30",
      "Service": "Wednesday",
      "Stops": "Sivaganga - SVGA, Manamadurai Jn - MNM,Ramanathapuram - RMD",
      "routeNo": 1
    },
    {
      "Id": 9,
      "Train": "07355 - UBL RMM EXP",
      "Destination": "Ramanathapuram",
      "Arrival": "02:10",
      "Departure": "02:12",
      "Service": "Saturday",
      "Stops": "Sivaganga - SVGA, Manamadurai Jn - MNM,Ramanathapuram - RMD",
      "routeNo": 1
    },
    {
      "Id": 10,
      "Train": "16850 - Rameswaram Tiruchchirappalli Express",
      "Destination": "Tiruchirappalli",
      "Arrival": "17:53",
      "Departure": "17:55",
      "Service": "",
      "Stops": "Devakottai Road, Kallal, Sivaganga, Manamadurai Jn, Paramakkudi, Ramanathapuram, Mandapam, Pamban Jn",
      "routeNo": 1
    },
    {
      "Id": 11,
      "Train": "BBS RMM SF (20896)",
      "Destination": "Mandapam",
      "Arrival": "18:10",
      "Departure": "18:15",
      "Service": "Friday",
      "Stops": "DEVAKOTTAI ROAD - DKO, SIVAGANGA - SVGA, MANAMADURAI JN - MNM, PARAMAKKUDI - PMK, RAMANATHAPURAM - RMD, MANDAPAM - MMM, RAMESWARAM - RMM",
      "routeNo": 1
    },
    {
      "Id": 12,
      "Train": "22614 - AYC RMM EXPRESS",
      "Destination": "Ramanathapuram",
      "Arrival": "23:05",
      "Departure": "23:10",
      "Service": "Wednesday",
      "Stops": "MANAMADURAI JN - MNM, RAMANATHAPURAM - RMD, MANDAPAM - MMM",
      "routeNo": 1
    },
    {
      "Id": 13,
      "Train": "16751 - MS RMM EXPRESS",
      "Destination": "Mandapam",
      "Arrival": "04:07",
      "Departure": "04:12",
      "Service": "Daily",
      "Stops": "DEVAKOTTAI ROAD - DKO, KALLAL - KAL, SIVAGANGA - SVGA, MANAMADURAI JN - MNM, PARAMAKKUDI - PMK, RAMANATHAPURAM - RMD, MANDAPAM - MMM, PAMBAN JN - PBM",
      "routeNo": 1
    },
    {
      "Id": 14,
      "Train": "12606 - PALLAVAN SF EXP",
      "Destination": "Chennai Egmore",
      "Arrival": "05:35",
      "Departure": "05:35",
      "Service": "Daily",
      "Stops": "Pudukkotai, Tiruchchirappalli Jn, Srirangam, Lalgudi, Ariyalur, Vriddhachalam Jn, Villupuram Jn, Melmaruvathur, Chengalpattu Jn, Tambaram, Mambalam",
      "routeNo": 2
    },
    {
      "Id": 15,
      "Train": "16182 - SILAMBU EXPRESS",
      "Destination": "Chennai Egmore",
      "Arrival": "21:30",
      "Departure": "21:35",
      "Service": "Thursday, Saturday, Sunday",
      "Stops": "Pudukkottai - PDKT, Tiruchchirappalli Jn - TPJ, Vriddhachalam Jn - VRI, Villupuram Jn - VM, Melmaruvathur - MLMR, Chengalpattu Jn - CGL",
      "routeNo": 2
    },
    {
      "Id": 16,
      "Train": "20682 - SILAMBU SF EXP",
      "Destination": "Chennai Egmore",
      "Arrival": "21:30",
      "Departure": "21:32",
      "Service": "Thursday, Saturday, Sunday",
      "Stops": "Pudukkottai - PDKT, Tiruchchirappalli Jn - TPJ, Vriddhachalam Jn - VRI, Villupuram Jn - VM, Melmaruvathur - MLMR, Chengalpattu Jn - CGL",
      "routeNo": 2
    },
    {
      "Id": 17,
      "Train": "06022 - TEN MS SPL",
      "Destination": "Chennai Egmore",
      "Arrival": "17:35",
      "Departure": "17:40",
      "Service": "Friday",
      "Stops": "Pudukkottai - PDKT, Tiruchchirappalli Jn - TPJ, Vriddhachalam Jn - VRI, Villupuram Jn - VM, Melmaruvathur - MLMR, Chengalpattu Jn - CGL",
      "routeNo": 2
    },
    {
      "Id": 18,
      "Train": "22662 - RMM CHENNAI EXP",
      "Destination": "Chennai Egmore",
      "Arrival": "23:15",
      "Departure": "23:20",
      "Service": "Daily",
      "Stops": "PUDUKKOTTAI - PDKT, TIRUCHCHIRAPALI - TPJ, VRIDHACHALAM JN - VRI, VILLUPURAM JN - VM, TINDIVANAM - TMV, MELMARUVATTUR - MLMR, CHENGALPATTU JN - CGL, TAMBARAM - TBM, MAMBALAM - MBM, CHENNAI EGMORE - MS",
      "routeNo": 2
    },
    {
      "Id": 19,
      "Train": "PDY CAPE EXP (16861)",
      "Destination": "Kanniyakumari",
      "Arrival": "20:28",
      "Departure": "20:30",
      "Service": "Sunday",
      "Stops": "Devakottai Road (DKO), Sivaganga (SVGA), Manamadurai Junction (MNM), Aruppukkottai (APK), Virudunagar Junction (VPT), Satur (SRT), Kovilpatti (CVP), Tirunelveli (TEN), Valliyur (VLY), Nagercoil Junction (NCJ), Kanyakumari (CAPE)",
      "routeNo": 3
    },
    {
      "Id": 20,
      "Train": "16181 - SILAMBU EXPRESS",
      "Destination": "Sengottai",
      "Arrival": "03:25",
      "Departure": "03:30",
      "Service": "Wednesday, Friday, Saturdeay",
      "Stops": "Devakottai Road - DKO, Sivaganga - SVGA, Manamadurai Jn - MNM, Aruppukkottai - APK, Virudunagar Jn - VPT, Tiruttangal - TTL, Sivakasi - SVKS, Srivilliputtur - SVPR, Rajapalayam - RJPM, Sankarankovil - SNKL, Pamba Kovil Shandy - PBKS, Kadayanallur - KDNL, Tenkasi Jn - TSI",
      "routeNo": 3
    },
    {
      "Id": 21,
      "Train": "06016 - VLNK ERS SPL",
      "Destination": "Ernakulam Junction",
      "Arrival": "22:55",
      "Departure": "23:00",
      "Service": "Sunday",
      "Stops": "Manamadurai Jn, Aruppukkottai, Virudunagar Jn, Sivakasi, Rajapalayam, Tenkasi Jn, Sengottai, Tenmalai, Punalur, Auvaneswsarem, Kottarakara, Kundara,  Kollam Jn, Sasthankotta, Kayankulam Jn, Mavelikara, Chengannur, Tiruvalla, Changanaseri, Kottayam, Triupunittura",
      "routeNo": 3
    },
    {
      "Id": 22,
      "Train": "20683 - TBM SCT SF EXP",
      "Destination": "Sengottai",
      "Arrival": "04:55",
      "Departure": "05:00",
      "Service": "Tuesday,Thursday, Sunday",
      "Stops": "Aruppukkottai - APK, Virudunagar Jn - VPT, Tirunelveli Jn - TEN, Cheranmahadevi - SMD, Kallidaikurichi - KIC, Ambasamudram - ASD, Pavurchatram - PCM, Tenkasi Jn - TSI",
      "routeNo": 3
    },
    {
      "Id": 23,
      "Train": "16362 - VLNK ERS EXP",
      "Destination": "Ernakulam Junction",
      "Arrival": "23:00",
      "Departure": "23:05",
      "Service": "Tuesday,Sunday",
      "Stops": "Manamadurai Jn - MNM, Aruppukkottai - APK, Virudunagar Jn - VPT, Sivakasi - SVKS, Rajapalayam - RJPM, Sankarankovil - SNKL, Kadayanallur - KDNL, Tenkasi Jn - TSI, Sengottai - SCT, Tenmalai - TML, Punalur - PUU, Auvaneeswaram - AVS, Kottarakara - KKZ, Kundara - KUV, Kollam Jn - QLN, Sasthankotta - STKT, Karunagappalli - KPY, Kayamkulam Jn - KYJ, Mavelikara - MVLK, Chengannur - CNGR, Tiruvalla - TRVL, Changanassery - CGY, Kottayam - KTYM",
      "routeNo": 3
    },
    {
      "Id": 24,
      "Train": "20681 - SILAMBU SF EXP",
      "Destination": "Sengottai",
      "Arrival": "03:00",
      "Departure": "03:05",
      "Service": "Wednesday, Friday, Saturdeay",
      "Stops": "DEVAKOTTAI ROAD - DKO, SIVAGANGA - SVGA, MANAMADURAI JN - MNM, ARUPPUKKOTTAI - APK, VIRUDUNAGAR JN - VPT, TIRUTTANGAL - TTL, SIVAKASI - SVKS, SRIVILLIPUTTUR - SVPR, RAJAPALAYAM - RJPM, SANKARANKOVIL - SNKL, PMBAKVL SHANDY - PBKS, KADAYANALLUR - KDNL, TENKASI JN - TSI",
      "routeNo": 3
    },
    {
      "Id": 25,
      "Train": "02683 - TBM SCT SF SPL",
      "Destination": "Sengottai",
      "Arrival": "03:10",
      "Departure": "03:15",
      "Service": "Saturday",
      "Stops": "ARUPPUKKOTTAI - APK, VIRUDUNAGAR JN - VPT, TIRUNELVELI JN - TEN, CHARANMAHADEVI - SMD, AMBASAMUDRAM - ASD, PAVURCHATRAM - PCM, TENKASI JN - TSI",
      "routeNo": 3
    },
    {
      "Id": 26,
      "Train": "06021 - TBM TEN SPL",
      "Destination": "Tirunelveli",
      "Arrival": "03:15",
      "Departure": "03:20",
      "Service": "Thursday",
      "Stops": "Sivaganga SVGA, Manamadurai Junction MNM, Aruppukottai APK, Virudunagar Junction VPT, Satur SRT, Kovilpatti CVP",
      "routeNo": 3
    },
    {
      "Id": 27,
      "Train": "56833 - Karaikkudi virudhunagar Passenger",
      "Destination": "virudhunagar",
      "Arrival": "Start",
      "Departure": "18:10",
      "Service": "Except Sunday",
      "Stops": "Sivaganga SVGA, Manamadurai Junction MNM, Aruppukottai APK,",
      "routeNo": 3
    },
    {
      "Id": 28,
      "Train": "16862 CAPE PDY EXP",
      "Destination": "Puducherry",
      "Arrival": "20:13",
      "Departure": "20:15",
      "Service": "Monday",
      "Stops": "Pudukkotai, Tiruchchirappalli Jn (RL), Thanjavur, Kumbakonam, Mayiladuturai Jn, Sirkazhi, Chidambaram, Tiruppadirippuliyur, Villupuram Jn (Rev)",
      "routeNo": 4
    },
    {
      "Id": 29,
      "Train": "22535 RMM BSBS SF EXP",
      "Destination": " Banaras",
      "Arrival": "02:58",
      "Departure": "03:00",
      "Service": "Sunday",
      "Stops": "Tiruchchirappalli Jn, Thanjavur, Kumbakonam, Mayiladuturai Jn, Sirkazhi, Chidambaram, Cuddalore Port Jn, Villupuram Jn, Melmaruvathur, Chengalpattu Jn, Chennai Egmore (RL), Chennai Beach Jn, Gudur Jn, Nellore, Ongole, Tenali Jn, Vijayawada Jn (RL), Warangal, Ramagundam, Sirpur Kaghaznagar, Balharshah, Chandrapur Maharashtra, Sevagram, Nagpur (RL), Itarsi Jn, Jabalpur, Katni, Maihar, Satna, Prayagrajcheoki, Naini Jn, Prayagraj Jn, Gyanpur Road",
      "routeNo": 4
    },
    {
      "Id": 30,
      "Train": "18495 - BHUBANESWAR EXP",
      "Destination": "Bhubaneswar",
      "Arrival": "11:40",
      "Departure": "11:42",
      "Service": "Sunday",
      "Stops": "Pudukkottai - PDKT, Tiruchchirappalli Jn - TPJ, Thanjavur Jn - TJ, Kumbakonam - KMU, Mayiladuturai Jn - MV, Sirkazhi - SY, Chidambaram - CDM, Tirupadripulyur - TDPR, Villupuram Jn - VM, Melmaruvathur - MLMR, Chennai Egmore - MS, Gudur Jn - GDR, Nellore - NLR, Vijayawada Jn - BZA, Rajahmundry - RJY, Duvvada - DVD, Visakhapatnam Jn - VSKP, Vizianagaram Jn - VZM",
      "routeNo": 4
    },
    {
      "Id": 31,
      "Train": "06015 - ERS VLNK SPL",
      "Destination": "Vellankanni",
      "Arrival": "00:45",
      "Departure": "00:50",
      "Service": "Saturday",
      "Stops": "Tiruchchirapali,Thanjavur,Thiruvarur Jn, Nagappattinam",
      "routeNo": 4
    },
    {
      "Id": 32,
      "Train": "22613 - RMM AYC SF EXP",
      "Destination": "Ayodhya Cantt",
      "Arrival": "02:55",
      "Departure": "03:00",
      "Service": "Monday",
      "Stops": " IRUCHCHIRAPALI - TPJ, THANJAVUR JN - TJ, KUMBAKONAM - KMU, MAYILADUTURAI J - MV, TIRUPADRIPULYUR - TDPR, VILLUPURAM JN - VM, MELMARUVATTUR - MLMR, CHENNAI EGMORE - MS, CHENNAI BEACH - MSB, GUDUR JN - GDR, VIJAYAWADA JN - BZA, WARANGAL - WL, BALHARSHAH - BPQ, CHANDRAPUR - CD, NAGPUR - NGP, ITARSI JN - ET, JABALPUR - JBP, SATNA - STA, NAINI - NYN, PRAYAGRAJ JN. - PRYJ, JAUNPUR JN - JNU, SHAHGANJ JN - SHG",
      "routeNo": 4
    },
    {
      "Id": 33,
      "Train": "06040 - KKDI MAS SPL",
      "Destination": "Chennai Central",
      "Arrival": "21:25",
      "Departure": "21:30",
      "Service": "Monday",
      "Stops": " IRUCHCHIRAPALI - TPJ, THANJAVUR JN - TJ, KUMBAKONAM - KMU, MAYILADUTURAI J - MV, TIRUPADRIPULYUR - TDPR, VILLUPURAM JN - VM, MELMARUVATTUR - MLMR, CHENNAI EGMORE - MS, CHENNAI BEACH - MSB, GUDUR JN - GDR, VIJAYAWADA JN - BZA, WARANGAL - WL, BALHARSHAH - BPQ, CHANDRAPUR - CD, NAGPUR - NGP, ITARSI JN - ET, JABALPUR - JBP, SATNA - STA, NAINI - NYN, PRAYAGRAJ JN. - PRYJ, JAUNPUR JN - JNU, SHAHGANJ JN - SHG",
      "routeNo": 4
    },
    {
      "Id": 34,
      "Train": "16752 - RMM MS EXPRESS",
      "Destination": "Chennai Egmore",
      "Arrival": "20:30",
      "Departure": "20:32",
      "Service": "Daily",
      "Stops": "PUDUKKOTTAI - PDKT, TIRUCHCHIRAPALI - TPJ, THANJAVUR JN - TJ, KUMBAKONAM - KMU, MAYILADUTURAI J - MV, SIRKAZHI - SY, CHIDAMBARAM - CDM, CUDDALORE PORT - CUPJ, VILLUPURAM JN - VM, MELMARUVATTUR - MLMR, CHENGALPATTU JN - CGL, TAMBARAM - TBM, MAMBALAM - MBM",
      "routeNo": 4
    },
    {
      "Id": 35,
      "Train": "06848 KKDI-TVR DMU PASS SPL",
      "Destination": "Thiruvarur",
      "Arrival": "",
      "Departure": "",
      "Service": "Except Sunday",
      "Stops": "Kandanur Puduvayal, Periyakottai, Valaramanikkam, Arantangi, Ayingudi, Peravuruni, Ottankadu, Pattukkottai, Adirampattinam, Mutupet, Tillaivilagam, Tiruturaipundi Jn, Manali Tn, Alattambadi, Ammanur, Tirunellikaval, Mavur Road, Mangudi",
      "routeNo": 5
    },
    {
      "Id": 36,
      "Train": "20684 - SCT TBM SF EXP",
      "Destination": "Tambaram",
      "Arrival": "21:30",
      "Departure": "21:35",
      "Service": "Monday,Wednesday, Friday",
      "Stops": "Arantangi - ATQ, Pattukottai - PKT, Mutupet - MTT, Tiruturaipundi Jn - TTP, Thiruvarur Jn - TVR, Mayiladuturai Jn - MV, Tirupadripulyur - TDPR, Villupuram Jn - VM, Melmaruvathur - MLMR",
      "routeNo": 5
    },
    {
      "Id": 37,
      "Train": "16361 - ERS VLNK EXP",
      "Destination": "Vellankanni",
      "Arrival": "00:50",
      "Departure": "00:55",
      "Service": "Monday,Saturday",
      "Stops": "Arantangi - ATQ, Peravurani - PVI, Pattukottai - PKT, Adirampattinam - AMM, Tiruturaipundi Jn - TTP, Thiruvarur Jn - TVR,Nagappattinam Jn - NGT",
      "routeNo": 5
    },
    {
      "Id": 38,
      "Train": "06198 - Karaikkudi Thiruvarur Passenger",
      "Destination": "Thiruvarur",
      "Arrival": "Start",
      "Departure": "18:00",
      "Service": "Daily",
      "Stops": "Kandanur Puduvayal, Periyakottai, Valaramanikkam, Arantangi, Ayingudi, Peravuruni, Ottankadu, Pattukkottai, Adirampattinam, Mutupet, Tillaivilagam, Tiruturaipundi Jn, Manali Tn, Alattambadi, Ammanur, Tirunellikaval, Mavur Road, Mangudi",
      "routeNo": 5
    },
    {
      "Id": 39,
      "Train": "07696 - RMD SC SPECIAL",
      "Destination": "Secunderabad",
      "Arrival": "12:00",
      "Departure": "12:05",
      "Service": "Friday",
      "Stops": "ARANTANGI - ATQ, PERAVURANI - PVI, PATTUKOTTAI - PKT, ATIRAMPATTINAM - AMM, MUTUPET - MTT, TIRUTURAIPUNDI - TTP, THIRUVARUR JN - TVR, MAYILADUTURAI J - MV, SIRKAZHI - SY, CHIDAMBARAM - CDM, TIRUPADRIPULYUR - TDPR, VILLUPURAM JN - VM, CHENGALPATTU JN - CGL, TAMBARAM - TBM, CHENNAI EGMORE - MS, CHENNAI BEACH - MSB, NAYUDUPETA - NYP, GUDUR JN - GDR, NELLORE - NLR, KAVALI - KVZ, ONGOLE - OGL, BAPATLA - BPP, TENALI JN - TEL, GUNTUR JN - GNT, SATTENAPALLE - SAP, MIRYALAGUDA - MRGA, NALGONDA - NLDA",
      "routeNo": 5
    },
    {
      "Id": 40,
      "Train": "16617 RMM CBE EXPRESS",
      "Destination": "Coimbatore",
      "Arrival": "22:33",
      "Departure": "22:35",
      "Service": "Wednesday",
      "Stops": "Pudukkotai, Tiruchchirappalli Jn, Karur, Erode Jn, Tiruppur, Coimbatore North Jn",
      "routeNo": 6
    },
    {
      "Id": 41,
      "Train": "07356 - RMM UBL SPL",
      "Destination": "Hubli",
      "Arrival": "00:03",
      "Departure": "00:05",
      "Service": "Sunday",
      "Stops": "PUDUKKOTTAI - PDKT, TIRUCHCHIRAPALI - TPJ, KARUR JN - KRR, NAMAKKAL - NMKL, SALEM JN - SA, OMALUR - OML, DHARMAPURI - DPJ, HOSUR - HSRA, BANASWADI - BAND, YESVANTPUR JN - YPR, TUMAKURU - TK, SAMPIGE ROAD - SPGR, TIPTUR - TTR, ARSIKERE JN - ASK, BIRUR JN - RRB, CHIKJAJUR JN - JRU, DAVANGERE - DVG, HARIHAR - HRR, RANIBENNUR - RNR, MMAILARA HAVERI - HVR, KARAJGI - KJG",
      "routeNo": 6
    },
    {
      "Id": 42,
      "Train": "12605 PALLAVAN SF EXP",
      "Destination": "Karaikkudi",
      "Arrival": "22:30",
      "Departure": "Halt",
      "Service": "Daily",
      "Stops": "",
      "routeNo": 7
    },
    {
      "Id": 43,
      "Train": "06847 TVR-KKDI DMU PASS SPL",
      "Destination": "Karaikkudi",
      "Arrival": "",
      "Departure": "",
      "Service": "Except Sunday",
      "Stops": "",
      "routeNo": 7
    },
    {
      "Id": 44,
      "Train": "06039 - MAS KKDI SPL",
      "Destination": "Karaikkudi",
      "Arrival": "",
      "Departure": "09:30",
      "Service": "Sunday",
      "Stops": "",
      "routeNo": 7
    },
    {
      "Id": 45,
      "Train": "6197 -Thiruvarur Karaikkudi Passenger",
      "Destination": "Karaikkudi",
      "Arrival": "09:35",
      "Departure": "Halt",
      "Service": "",
      "Stops": "",
      "routeNo": 7
    },
    {
      "Id": 46,
      "Train": "56831 - Tiruchchirappalli Karaikudi Passenger",
      "Destination": "Karaikkudi",
      "Arrival": "17:55",
      "Departure": "Halt",
      "Service": "",
      "Stops": "",
      "routeNo": 7
    },
    {
      "Id": 47,
      "Train": "56834 - Virudhunagar Karaikkudi Passenger",
      "Destination": "Karaikkudi",
      "Arrival": "09:25",
      "Departure": "Halt",
      "Service": "",
      "Stops": "",
      "routeNo": 7
    }
  ]
  


  
  export default Train