const HC03 =
[
  {
    "Id": 1,
    "Heading": "RCC Slab Calculation Type 1",
    "Description1": "Assumptions Example Calculation: 1000 sq ft RCC Slab:",
    "Description2": "Slab area: 1000 sq ft and Thickness: 5 inches (ft / 12 = 0.4167 ft)",
    "Description3": "Concrete grade: M20 and Mix ratio: 1:1.5:3 (cement:sand:aggregate)",
    "Description4": "Thumb Rule for Material Quantities:",
    "Description5": "Steel: 80 kg/cubic meter (m³), Cement: 5 kg/sq ft, Sand: 0.175 cubic feet (cft) / sq ft, Aggregate: 0.350 cft / sq ft and Labor & Shuttering: ₹60/sq ft",
    "Heading2": "RCC ஸ்லாப் கணக்கீடு வகை 1",
    "Description6": "அனுமானங்களின் எடுத்துக்காட்டு கணக்கீடு: 1000 சதுர அடி RCC ஸ்லாப்:",
    "Description7": "ஸ்லாப் பகுதி: 1000 சதுர அடி மற்றும் தடிமன்: 5 அங்குலம் (அடி / 12 = 0.4167 அடி)",
    "Description8": "கான்கிரீட் தரம்: M20 மற்றும் கலவை விகிதம்: 1:1.5:3 (சிமெண்ட்:மணல்:மொத்தம்)",
    "Description9": "பொருள் அளவுகளுக்கான கட்டைவிரல் விதி:",
    "Description10": "எஃகு: 80 கிலோ/கன மீட்டர் (m³), சிமெண்ட்: 5 கிலோ/சதுர அடி, மணல்: 0.175 கன அடி (cft) / சதுர அடி, மொத்தம்: 0.350 cft / sq ft மற்றும் லேபர் & ஷட்டரிங்: ₹60/சதுர அடி"
  },
  {
    "Id": 2,
    "Heading": "RCC Slab Calculation Type 2",
    "Description1": "Volume of RCC Slab: 1000 sq ft x 0.4167 ft = 416.6 cft",
    "Description2": "Steel Quantity: 80 kg/m³ x 11.8 m³ = 944 kg (approx. 945 kg)",
    "Description3": "Cement Quantity: 5 kg/sq ft x 1000 sq ft = 5000 kg (100 bags of 50 kg each)",
    "Description4": "Sand Quantity: 0.175 cft/sq ft x 1000 sq ft = 175 cft",
    "Description5": "Aggregate Quantity: 0.350 cft/sq ft x 1000 sq ft = 350 cft",
    "Heading2": "RCC ஸ்லாப் கணக்கீடு வகை 2",
    "Description6": "RCC ஸ்லாப்பின் அளவு: 1000 சதுர அடி x 0.4167 அடி = 416.6 cft",
    "Description7": "எஃகு அளவு: 80 கிலோ/மீ³ x 11.8 மீ³ = 944 கிலோ (தோராயமாக. 945 கிலோ)",
    "Description8": "சிமெண்ட் அளவு: 5 கிலோ/சதுர அடி x 1000 சதுர அடி = 5000 கிலோ (ஒவ்வொன்றும் 50 கிலோ கொண்ட 100 பைகள்)",
    "Description9": "மணல் அளவு: 0.175 cft/sq ft x 1000 sq ft = 175 cft",
    "Description10": "மொத்த அளவு: 0.350 cft/sq ft x 1000 sq ft = 350 cft"
  },
  {
    "Id": 3,
    "Heading": "RCC Slab Calculation Type 3",
    "Description1": "Steel (₹60/kg): 945 kg x ₹60/kg = ₹56,700",
    "Description2": "Cement (₹350/bag): 100 bags x ₹350/bag = ₹35,000",
    "Description3": "Sand (₹40/cft): 175 cft x ₹40/cft = ₹7,000",
    "Description4": "Aggregate (₹50/cft): 350 cft x ₹50/cft = ₹17,500",
    "Description5": "Labor & Shuttering (₹60/sq ft): 1000 sq ft x ₹60/sq ft = ₹60,000. Total Cost: ₹176,200",
    "Heading2": "RCC ஸ்லாப் கணக்கீடு வகை 3",
    "Description6": "ஸ்டீல் (₹60/கிலோ): 945 கிலோ x ₹60/கிலோ = ₹56,700",
    "Description7": "சிமெண்ட் (₹350/பை): 100 பைகள் x ₹350/பை = ₹35,000",
    "Description8": "மணல் (₹40/cft): 175 cft x ₹40/cft = ₹7,000",
    "Description9": "மொத்தம் (₹50/cft): 350 cft x ₹50/cft = ₹17,500",
    "Description10": "லேபர் & ஷட்டரிங் (₹60/சது அடி): 1000 சதுர அடி x ₹60/சது அடி = ₹60,000. மொத்த செலவு: ₹176,200"
  }
]


  
  export default HC03 