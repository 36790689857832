const KR = [
  { Id: 1, To: "Kallal", stops: "Kallal, Kalaiyar Kovil, Elayankudi" },
  { Id: 2, To: "Paramakudi", stops: "Kallal, Kalaiyar Kovil, Elayankudi" },
  { Id: 3, To: "Elayankudi", stops: "Kallal, Kalaiyar Kovil, Elayankudi" },
  { Id: 4, To: "Kalaiyar Kovil", stops: "Kallal, Kalaiyar Kovil, Elayankudi" },
  { Id: 5, To: "Madurai", stops: "Kallal" },
  { Id: 6, To: "Sivaganga", stops: "Kallal" },
  {
    Id: 7,
    To: "Tiruchendur",
    stops: "Kallal, Kalaiyar Kovil, Elayankudi, Paramakudi",
  },
  {
    Id: 8,
    To: "Mudhukulathur",
    stops: "Kallal, Kalaiyar Kovil, Elayankudi, Paramakudi",
  },
  {
    Id: 9,
    To: "Kamudi",
    stops: "Kallal, Kalaiyar Kovil, Elayankudi, Paramakudi",
  },
];

export default KR;
