import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import TR from "../data/TR";

import "../components/UI/styles.css";
import ThiruppathurRoute from "../components/ThiruppathurRoute";

const ThiruppathurRouteScreen = () => {
  return (
    <>
      <Link className="btn btn-light my-3" to="/bus">
        Go Back
      </Link>
      <section className="register text-success py-3 overflow-hidden">
        <h1 className="text-center py-2">Buses Via Thiruppathur Route</h1>
        <Row>
          {TR.map((route) => (
            <Col key={route._id} sm={12} md={12} lg={12} xl={12}>
              <ThiruppathurRoute route={route} />
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default ThiruppathurRouteScreen;
