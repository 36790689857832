const Route =
[
    {
      "Id": 1,
      "To": "Madurai",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, SS Kottai, Melur"
    },
    {
      "Id": 2,
      "To": "Thiruppathur",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, SS Kottai, Melur"
    },
    {
      "Id": 3,
      "To": "Dindigul",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Singampunari, Kottampatti, Naththam"
    },
    { "Id": 4, "To": "Nachiyaruram", "stops": "Nachiyaruram, Thiruppathur" },
    { "Id": 5, "To": "Sivaganga", "stops": "Nachiyaruram, Thiruppathur" },
    {
      "Id": 6,
      "To": "Coimbatore",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Singampunari, Kottampatti, Naththam, Dindigul, Ottanchathiram, Tarapuram"
    },
    {
      "Id": 7,
      "To": "Palani",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Singampunari, Kottampatti, Naththam, Dindigul"
    },
    {
      "Id": 8,
      "To": "Sivakasi",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, SS Kottai, Melur, Madurai"
    },
    {
      "Id": 9,
      "To": "Erode",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Singampunari, Kottampatti, Naththam, Dindigul"
    },
    {
      "Id": 10,
      "To": "Theni",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, SS Kottai, Melur, Madurai"
    },
    {
      "Id": 11,
      "To": "Kumuli",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, SS Kottai, Melur, Madurai, Theni"
    },
    {
      "Id": 12,
      "To": "Thirunelveli",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, SS Kottai, Melur, Madurai"
    },
    {
      "Id": 13,
      "To": "Tiruppur",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Singampunari, Kottampatti, Naththam, Dindigul, Ottanchathiram, Tarapuram"
    },
    {
      "Id": 14,
      "To": "Mettupalayam",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Singampunari, Kottampatti, Naththam, Dindigul, Ottanchathiram, Tarapuram, Tiruppur"
    },
    { "Id": 15, "To": "Ponnamaravathy", "stops": "Kundrakudi, Pillaiyar Patti" },
    { "Id": 16, "To": "Vempathur", "stops": "Vempathur" },
    {
      "Id": 17,
      "To": "Paramakudi",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Sivaganga"
    },
    {
      "Id": 18,
      "To": "Nagercoil",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, SS Kottai, Melur, Madurai, Tirunelveli"
    },
    {
      "Id": 19,
      "To": "Kodaikanal",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Singampunari, Kottampatti, Naththam, Dindigul"
    },
    {
      "Id": 20,
      "To": "Tiruppuvanam",
      "stops": "Kundrakudi, Pillaiyar Patti, Thiruppathur, Sivaganga"
    }
  ]
  

  
  export default Route 