import { Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "../components/UI/styles.css";



const BusScreen = () => {
  const navigate = useNavigate();
  const introductionHandler = () => {
    navigate("/");
  };

  const aranthankiHandler = () => {
    navigate("/aranthanki");
  };
  const devakottaiHandler = () => {
    navigate("/devakottai");
  };

  const pudukottaiHandler = () => {
    navigate("/pudukottai");
  };

  const thiruppathurHandler = () => {
    navigate("/thiruppathur");
  };

  const kallalHandler = () => {
    navigate("/kallal");
  };
  const PrivateHandler = () => {
    navigate("/private");
  };
 

  return (
    <>
      <Row>
        <img
          src="images/Bus.png"
          alt=""
          className="img-fluid roundedCircle width=50%"
        />
      </Row>
      <form>
      <div className="d-flex justify-content-center">
          <Button
            className="btn-secondary mx-2  mb-2 mt-4"
            type="button"
            style={{width: 400}}
            onClick={() => {
              thiruppathurHandler();
            }}
          >
            Thiruppathur Route
          </Button>
        </div>
      <div className="d-flex justify-content-center">
          <Button
            className="btn-secondary mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              pudukottaiHandler();
            }}
          >
            Pudukottai Route
          </Button>
        </div>
        
        <div className="d-flex justify-content-center">
          <Button
            className="btn-secondary mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              devakottaiHandler();
            }}
          >
            Devakottai Route
          </Button>
        </div>
        <div className="d-flex justify-content-center">
        <Button
            className="btn-secondary btn mx-2 mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              aranthankiHandler();
            }}
          >
            Aranthanki Route
          </Button>
          </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-secondary mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              kallalHandler();
            }}
          >
            Kallal Route
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-secondary mx-2  mb-2"
            type="button"
            style={{width: 400}}
            onClick={() => {
              PrivateHandler();
            }}
          >
            Private Route
          </Button>
        </div>
      </form>
 
    </>
  );
};

export default BusScreen;
