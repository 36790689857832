import { FaCalculator } from "react-icons/fa";

const Calculation = ({ product }) => {
  return (
    <>
      <section className="register bg-white py-6 overflow-hidden">
        <div className="container position-relative">
          <div className="text-center mb-5">
          <FaCalculator size={56} className="text-warning me-2" />
          <hr className="w-25 mx-auto" />
          <h4 className="text-uppercase fw-bold text-primary mt-2">
              {product.Heading}
            </h4>
           
            <hr className="w-25 mx-auto" />
          </div>
          <div className="row">
          
            <h6 className="fs-6">{product.Description1}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description2}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description3}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description4}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description5}</h6>
            <div className="text-center mb-5">
            <h4 className="text-uppercase fw-bold text-primary">
              {product.Heading2}
            </h4>
            </div>
            <h6 className="fs-6">{product.Description6}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description7}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description8}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description9}</h6>
            <hr></hr>
            <h6 className="fs-6">{product.Description10}</h6>
            <hr></hr>
            
            <hr className="w-25 mx-auto" />
          </div>
          <div className="row">
            
          
          
            <i className="fas FaHandPointRight fa-3x text-primary mb-3"></i>
            <h3 className="fs-5">{product.Description}</h3>
            <hr className="w-25 mx-auto" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Calculation;
